import React from "react";
import Seo from "../components/Seo/Seo";
import Layout from "../layout";
import Contact from "../components/Contact/Contact";
import config from "../../data/SiteConfig";
import { graphql } from "gatsby";

const ContactPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      <div className="contact-container">
        <Seo
          title={`Contact`}
          description={config.siteDescription4}
          article={false}
        />
        {directus.contact && (
          <Contact contact={directus.contact} page={true} background={true} />
        )}
      </div>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;
